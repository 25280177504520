import React from 'react';
import { Link } from 'react-router-dom';
import { useCurrency } from '../contexts/CurrencyContext';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../data/translations';

const Header = () => {
  const { currency, setCurrency } = useCurrency();
  const { language, setLanguage } = useLanguage();
  const currencies = ['RUB', 'USD', 'EUR'];
  const languages = ['ru', 'en', 'zh'];

  return (
    <header className="bg-green-600 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold">GreenHome</Link>
        <div className="flex items-center space-x-4">
          <select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className="bg-white text-gray-800 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-white"
          >
            {currencies.map(curr => (
              <option key={curr} value={curr}>{curr}</option>
            ))}
          </select>
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            className="bg-white text-gray-800 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-white"
          >
            {languages.map(lang => (
              <option key={lang} value={lang}>{lang.toUpperCase()}</option>
            ))}
          </select>
        </div>
      </div>
    </header>
  );
};

export default Header;