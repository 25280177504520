import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import StarRating from './StarRating';
import { useCart } from '../contexts/CartContext';
import { useCurrency } from '../contexts/CurrencyContext';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../data/translations';

const ProductModal = ({ product, isOpen, onClose }) => {
  const { addToCart } = useCart();
  const { convertPrice, formatCurrency } = useCurrency();
  const { language } = useLanguage();

  if (!isOpen || !product) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white p-6 rounded-lg max-w-md w-full"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{product.name}</h2>
              <button onClick={onClose}><X /></button>
            </div>
            <img src={product.image} alt={product.name} className="w-full h-64 object-cover mb-4 rounded" />
            <StarRating rating={product.rating} />
            <p className="mt-2">{translations[language].price}: {formatCurrency(convertPrice(product.price))}</p>
            <p className="mt-2">{translations[language].category}: {product.category}</p>
            <button 
              className="w-full bg-green-600 text-white py-2 mt-4 rounded hover:bg-green-700 transition-colors"
              onClick={() => {
                addToCart(product);
                onClose();
              }}
            >
              {translations[language].addToCart}
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ProductModal;