import React from 'react';
import { Search } from 'lucide-react';
import { products } from '../data/products';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../data/translations';

const FilterBar = ({ searchTerm, setSearchTerm, selectedCategory, setSelectedCategory }) => {
  const { language } = useLanguage();
  const categories = ['All', ...new Set(products.map(product => product.category))];

  return (
    <div className="mb-6">
      <div className="container mx-auto py-4">
        <div className="flex items-center space-x-4">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder={translations[language].search}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600"
          >
            {categories.map(category => (
              <option key={category} value={category}>
                {category === 'All' ? translations[language].all : category}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;