import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, User, ShoppingBag } from 'lucide-react';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../data/translations';

const BottomNavigation = () => {
  const location = useLocation();
  const { language } = useLanguage();

  const isActive = (path) => {
    return location.pathname === path ? 'text-green-600' : 'text-gray-600';
  };

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 flex justify-around items-center h-16">
      <Link to="/user" className={`flex flex-col items-center ${isActive('/user')}`}>
        <User size={24} />
        <span className="text-xs mt-1">{translations[language].profile}</span>
      </Link>
      <Link to="/" className={`flex flex-col items-center ${isActive('/')}`}>
        <Home size={24} />
        <span className="text-xs mt-1">{translations[language].home}</span>
      </Link>
      <Link to="/checkout" className={`flex flex-col items-center ${isActive('/checkout')}`}>
        <ShoppingBag size={24} />
        <span className="text-xs mt-1">{translations[language].cart}</span>
      </Link>
    </nav>
  );
};

export default BottomNavigation;