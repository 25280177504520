export const translations = {
  ru: {
    home: 'Главная',
    profile: 'Профиль',
    cart: 'Корзина',
    checkout: 'Оформление заказа',
    addToCart: 'В корзину',
    total: 'Итого',
    search: 'Поиск',
    category: 'Категория',
    all: 'Все',
    name: 'Имя',
    email: 'Email',
    address: 'Адрес',
    placeOrder: 'Оформить заказ',
    orderSuccess: 'Заказ успешно оформлен!',
    yourOrder: 'Ваш заказ',
    price: 'Цена',
    quantity: 'Количество',
    remove: 'Удалить',
    emptyCart: 'Корзина пуста',
    orderHistory: 'История заказов',
    order: 'Заказ',
    date: 'Дата',
    // Новые ключи
    save: 'Сохранить',
    totalSpent: 'Всего потрачено',
    totalOrders: 'Всего заказов',
    items: 'Товары',
    editProfile: 'Редактировать профиль',
  },
  en: {
    home: 'Home',
    profile: 'Profile',
    cart: 'Cart',
    checkout: 'Checkout',
    addToCart: 'Add to Cart',
    total: 'Total',
    search: 'Search',
    category: 'Category',
    all: 'All',
    name: 'Name',
    email: 'Email',
    address: 'Address',
    placeOrder: 'Place Order',
    orderSuccess: 'Order placed successfully!',
    yourOrder: 'Your Order',
    price: 'Price',
    quantity: 'Quantity',
    remove: 'Remove',
    emptyCart: 'Cart is empty',
    orderHistory: 'Order History',
    order: 'Order',
    date: 'Date',
    // New keys
    save: 'Save',
    totalSpent: 'Total Spent',
    totalOrders: 'Total Orders',
    items: 'Items',
    editProfile: 'Edit Profile',
  },
  zh: {
    home: '首页',
    profile: '个人资料',
    cart: '购物车',
    checkout: '结账',
    addToCart: '加入购物车',
    total: '总计',
    search: '搜索',
    category: '类别',
    all: '全部',
    name: '姓名',
    email: '电子邮件',
    address: '地址',
    placeOrder: '下单',
    orderSuccess: '订单已成功提交！',
    yourOrder: '您的订单',
    price: '价格',
    quantity: '数量',
    remove: '删除',
    emptyCart: '购物车为空',
    orderHistory: '订单历史',
    order: '订单',
    date: '日期',
    // 新键
    save: '保存',
    totalSpent: '总消费',
    totalOrders: '总订单数',
    items: '商品',
    editProfile: '编辑资料',
  },
};