import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { useCurrency } from '../contexts/CurrencyContext';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../data/translations';
import { Minus, Plus, X } from 'lucide-react';

const CheckoutPage = () => {
  const { cartItems, updateQuantity, removeFromCart } = useCart();
  const { convertPrice, formatCurrency } = useCurrency();
  const { language } = useLanguage();

  const total = cartItems.reduce((sum, item) => sum + convertPrice(item.price) * item.quantity, 0);

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-2xl font-bold mb-4">{translations[language].cart}</h1>
      {cartItems.length === 0 ? (
        <p>{translations[language].emptyCart}</p>
      ) : (
        <>
          <div className="bg-white rounded-lg shadow-md p-6 mb-4">
            {cartItems.map(item => (
              <div key={item.id} className="flex items-center py-4 border-b">
                <img src={item.image} alt={item.name} className="w-20 h-20 object-cover rounded mr-4" />
                <div className="flex-grow">
                  <h3 className="font-semibold">{item.name}</h3>
                  <p className="text-gray-600">{formatCurrency(convertPrice(item.price))}</p>
                </div>
                <div className="flex items-center">
                  <button 
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    className="p-1 rounded-full hover:bg-gray-200"
                  >
                    <Minus size={16} />
                  </button>
                  <span className="mx-2">{item.quantity}</span>
                  <button 
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                    className="p-1 rounded-full hover:bg-gray-200"
                  >
                    <Plus size={16} />
                  </button>
                </div>
                <button 
                  onClick={() => removeFromCart(item.id)}
                  className="ml-4 p-1 rounded-full hover:bg-gray-200"
                >
                  <X size={20} />
                </button>
              </div>
            ))}
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex justify-between items-center mb-4">
              <span className="font-semibold text-lg">{translations[language].total}:</span>
              <span className="font-bold text-xl">{formatCurrency(total)}</span>
            </div>
            <Link 
              to="/checkout" 
              className="block w-full bg-green-600 text-white text-center py-2 rounded-lg hover:bg-green-700 transition-colors"
            >
              {translations[language].checkout}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckoutPage;