import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import BottomNavigation from './BottomNavigation';
import CartModal from './CartModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => {
  const [currency, setCurrency] = useState('RUB');
  const location = useLocation();

  return (
    <div className="bg-green-100 min-h-screen flex flex-col">
      <Header currency={currency} setCurrency={setCurrency} />
      <main className="container mx-auto py-8 flex-grow mb-16">
        {children}
      </main>
      <BottomNavigation />
      <CartModal />
      <ToastContainer position="bottom-right" autoClose={3000} />
    </div>
  );
};

export default Layout;