export const products = [
    // Flowers
    { id: 1, name: "Green Gelato", price: 1500, image: "images/5e3889d854891_green-gelato.jpg.png", rating: 4.5, category: 'Flowers' },
    { id: 2, name: "Banana Sapphire", price: 1800, image: "images/Banana Sapphire.jpg", rating: 4.7, category: 'Flowers' },
    { id: 3, name: "Critical Kush", price: 1800, image: "images/Critical Kush.gif", rating: 4.6, category: 'Flowers' },
    { id: 4, name: "Indigo Berry Kush", price: 1800, image: "images/Indigo Berry Kush.jpg", rating: 4.8, category: 'Flowers' },
    { id: 5, name: "Mouth Wash", price: 1800, image: "images/Mouth Wash.jpg", rating: 4.4, category: 'Flowers' },
    { id: 6, name: "BlueBerry OG", price: 1800, image: "images/BlueBerry OG.gif", rating: 4.9, category: 'Flowers' },
    { id: 7, name: "Pure Kush", price: 1800, image: "images/Pure Kush.jpg", rating: 4.7, category: 'Flowers' },
    { id: 8, name: "Bay 11", price: 1800, image: "images/Bay 11.jpg", rating: 4.5, category: 'Flowers' },
    { id: 9, name: "Black Cherry OG", price: 1800, image: "images/Black Cherry OG.jpg", rating: 4.8, category: 'Flowers' },
    { id: 10, name: "Critical Kush Bulk Puck", price: 1800, image: "images/Critical Kush Bulk Puck (Barneys Farm).jpg", rating: 4.6, category: 'Flowers' },

    // Pre-Rolls
    { id: 11, name: "Autumn- Presha 6 Pk", price: 1500, image: "images/Pre-Rolls/Autumn- Presha 6 Pk.avif", rating: 4.3, category: 'Pre-Rolls' },
    { id: 12, name: "Golden State Cannabis- Georgia Pie 1g", price: 1800, image: "images/Pre-Rolls/Golden State Cannabis- Georgia Pie 1g.avif", rating: 4.6, category: 'Pre-Rolls' },
    { id: 13, name: "Heavy Hitters-Diamond Infused- Apples & Bananas Multi Pk", price: 1800, image: "images/Pre-Rolls/Heavy Hitters-Diamond Infused- Apples & Bananas Multi Pk.avif", rating: 4.8, category: 'Pre-Rolls' },
    { id: 14, name: "Heavy Hitters-Diamond Infused- Blue Sherbert 1g", price: 1800, image: "images/Pre-Rolls/Heavy Hitters-Diamond Infused- Blue Sherbert 1g.avif", rating: 4.7, category: 'Pre-Rolls' },
    { id: 15, name: "Lowell- 35's Dreamweaver 10 Pk", price: 1800, image: "images/Pre-Rolls/Lowell- 35 Dreamweaver 10 Pk.avif", rating: 4.5, category: 'Pre-Rolls' },
    { id: 16, name: "Lowell- 35's Stargazer 10 Pk", price: 1800, image: "images/Pre-Rolls/Lowell- 35 Stargazer 10 Pk.avif", rating: 4.4, category: 'Pre-Rolls' },
    { id: 17, name: "Lowell- 35's Trailblazer 10 Pk Pre Rolls", price: 1800, image: "images/Pre-Rolls/Lowell- 35 Trailblazer 10 Pk Pre Rolls.avif", rating: 4.6, category: 'Pre-Rolls' },
    { id: 18, name: "Lowell Smokes- The Zen 6 Pk", price: 1800, image: "images/Pre-Rolls/Lowell Smokes- The Zen 6 Pk.avif", rating: 4.7, category: 'Pre-Rolls' },
    { id: 19, name: "Pacific Stone- 805 Glue 14 Pk", price: 1800, image: "images/Pre-Rolls/Pacific Stone- 805 Glue 14 Pk.avif", rating: 4.5, category: 'Pre-Rolls' },

    // Edibles
    { id: 20, name: "Almora- Classic Lemonade12oz 100mg", price: 1500, image: "images/Edibles/Almora- Classic Lemonade12oz 100mg.avif", rating: 4.4, category: 'Edibles' },
    { id: 21, name: "Almora-Live Resin Gummy- Mixed Berry & Pomegranate 100mg", price: 1800, image: "images/Edibles/Almora-Live Resin Gummy- Mixed Berry & Pomegranate 100mg.avif", rating: 4.6, category: 'Edibles' },
    { id: 22, name: "Camino-Excite Gummies- Wild Cherry", price: 1800, image: "images/Edibles/Camino-Excite Gummies- Wild Cherry.avif", rating: 4.7, category: 'Edibles' },
    { id: 23, name: "Camino-Sleep Chews- CBN - Boysenberry 100mg", price: 1800, image: "images/Edibles/Camino-Sleep Chews- CBN - Boysenberry 100mg.avif", rating: 4.8, category: 'Edibles' },
    { id: 24, name: "Camino-Uplifting Chews- Forest Berry 100mg", price: 1800, image: "images/Edibles/Camino-Uplifting Chews- Forest Berry 100mg.avif", rating: 4.5, category: 'Edibles' },
    { id: 25, name: "Heavy Hitters-Live Rosin- Blackberry Lemon 100mg Gummies", price: 1800, image: "images/Edibles/Heavy Hitters-Live Rosin- Blackberry Lemon 100mg Gummies.avif", rating: 4.9, category: 'Edibles' },
    { id: 26, name: "Heavy Hitters-Live Rosin- Guava Strawberry 100mg Gummies", price: 1800, image: "images/Edibles/Heavy Hitters-Live Rosin- Guava Strawberry 100mg Gummies.avif", rating: 4.7, category: 'Edibles' },
    { id: 27, name: "Kanha-Classic- Mango Gummies 100mg", price: 1800, image: "images/Edibles/Kanha-Classic- Mango Gummies 100mg.avif", rating: 4.6, category: 'Edibles' },
    { id: 28, name: "Kanha-Classic- Pink Guava 100mg", price: 1800, image: "images/Edibles/Kanha-Classic- Pink Guava 100mg.avif", rating: 4.5, category: 'Edibles' },

    // Concentrates
    { id: 29, name: "Almora-Badder Jar- Blueberry Cookies 1g", price: 1500, image: "images/Concentrates/Almora-Badder Jar- Blueberry Cookies 1g.avif", rating: 4.7, category: 'Concentrates' },
    // Continuing from the previous list...

    // Concentrates (continued)
    { id: 30, name: "Bear Labs-Tier 4 Live Rosin- Double Diesel 1g", price: 1800, image: "images/Concentrates/Bear Labs-Tier 4 Live Rosin- Double Diesel 1g.avif", rating: 4.8, category: 'Concentrates' },
    { id: 31, name: "Greenline-Badder- Honey Popz 1g", price: 1800, image: "images/Concentrates/Greenline-Badder- Honey Popz 1g.avif", rating: 4.6, category: 'Concentrates' },
    { id: 32, name: "Greenline-Sugar- Dragon Berry 1g", price: 1800, image: "images/Concentrates/Greenline-Sugar- Dragon Berry 1g.avif", rating: 4.5, category: 'Concentrates' },
    { id: 33, name: "Punch-Tier 4 Live Rosin Badder- Cereal Panther 1g", price: 1800, image: "images/Concentrates/Punch-Tier 4 Live Rosin Badder- Cereal Panther 1g.avif", rating: 4.9, category: 'Concentrates' },
    { id: 34, name: "Raw Garden - Zookies 1g Live Sauce", price: 1800, image: "images/Concentrates/Raw Garden - Zookies 1g Live Sauce.avif", rating: 4.7, category: 'Concentrates' },
    { id: 35, name: "WVY-Budder- Banana Runtz 1g", price: 1800, image: "images/Concentrates/WVY-Budder- Banana Runtz 1g.avif", rating: 4.6, category: 'Concentrates' },
    { id: 36, name: "WVY-Budder- Oil Spill 1g", price: 1800, image: "images/Concentrates/WVY-Budder- Oil Spill 1g.avif", rating: 4.5, category: 'Concentrates' },
    { id: 37, name: "WVY-Budder- Sour Banana Sherb 1g", price: 1800, image: "images/Concentrates/WVY-Budder- Sour Banana Sherb 1g.avif", rating: 4.8, category: 'Concentrates' },

    // Vape Cartridge
    { id: 38, name: "Almora-Purified Live Resin- Face Off OG 1g Cart", price: 1500, image: "images/Vape Cartridge/Almora-Purified Live Resin- Face Off OG 1g Cart.avif", rating: 4.6, category: 'Vape Cartridge' },
    { id: 39, name: "Almora-Purified Live Resin- Lemon Brulee 1g Cart", price: 1800, image: "images/Vape Cartridge/Almora-Purified Live Resin- Lemon Brulee 1g Cart.avif", rating: 4.7, category: 'Vape Cartridge' },
    { id: 40, name: "Cruisers- Berry Gelato Cart 1g", price: 1800, image: "images/Vape Cartridge/Cruisers- Berry Gelato Cart 1g.avif", rating: 4.5, category: 'Vape Cartridge' },
    { id: 41, name: "Ghost-Disposable- Black Mamba 1g", price: 1800, image: "images/Vape Cartridge/Ghost-Disposable- Black Mamba 1g.avif", rating: 4.8, category: 'Vape Cartridge' },
    { id: 42, name: "Gramlin Disposable- Blue Dream 1g", price: 1800, image: "images/Vape Cartridge/Gramlin Disposable- Blue Dream 1g.avif", rating: 4.6, category: 'Vape Cartridge' },
    { id: 43, name: "Gramlin Disposable- Raspberry OG 1g", price: 1800, image: "images/Vape Cartridge/Gramlin Disposable- Raspberry OG 1g.avif", rating: 4.7, category: 'Vape Cartridge' },
    { id: 44, name: "Heavy Hitters- Banana Punch 1g Cart", price: 1800, image: "images/Vape Cartridge/Heavy Hitters- Banana Punch 1g Cart.avif", rating: 4.9, category: 'Vape Cartridge' },
    { id: 45, name: "Plug Play-JUSTplay Disposable- Melon Dew 1g", price: 1800, image: "images/Vape Cartridge/Plug Play-JUSTplay Disposable- Melon Dew 1g.avif", rating: 4.7, category: 'Vape Cartridge' },
    { id: 46, name: "Stiiizy- Sour Diesel 1g pod", price: 1800, image: "images/Vape Cartridge/Stiiizy- Sour Diesel 1g pod.avif", rating: 4.8, category: 'Vape Cartridge' },

    // Accessories
    { id: 47, name: "BIC Lighter", price: 1500, image: "images/Accessories/BIC Lighter.avif", rating: 4.0, category: 'Accessories' },
    { id: 48, name: "BMC- Banger", price: 1800, image: "images/Accessories/BMC- Banger.avif", rating: 4.2, category: 'Accessories' },
    { id: 49, name: "BMC- Bong (Pink or Green)", price: 1800, image: "images/Accessories/BMC- Bong (Pink or Green).avif", rating: 4.5, category: 'Accessories' },
    { id: 50, name: "BMC- Herb Grinder (medium) mixed colors", price: 1800, image: "images/Accessories/BMC- Herb Grinder (medium) mixed colors.avif", rating: 4.3, category: 'Accessories' }
];