import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useCart } from '../contexts/CartContext';
import { useCurrency } from '../contexts/CurrencyContext';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../data/translations';

const CartModal = () => {
  const { isCartOpen, setIsCartOpen, cartItems, removeFromCart } = useCart();
  const { convertPrice, formatCurrency } = useCurrency();
  const { language } = useLanguage();

  if (!isCartOpen) return null;

  const total = cartItems.reduce((sum, item) => sum + convertPrice(item.price * item.quantity), 0);

  return (
    <AnimatePresence>
      {isCartOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white p-6 rounded-lg max-w-md w-full max-h-[80vh] overflow-y-auto"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{translations[language].cart}</h2>
              <button onClick={() => setIsCartOpen(false)}><X /></button>
            </div>
            {cartItems.length === 0 ? (
              <p>{translations[language].emptyCart}</p>
            ) : (
              <>
                {cartItems.map(item => (
                  <motion.div 
                    key={item.id} 
                    className="flex items-center mb-4 bg-gray-100 p-2 rounded-lg"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img src={item.image} alt={item.name} className="w-16 h-16 object-cover rounded-md mr-4" />
                    <div className="flex-grow">
                      <h3 className="font-semibold">{item.name}</h3>
                      <p>{translations[language].quantity}: {item.quantity}</p>
                      <p>{translations[language].price}: {formatCurrency(convertPrice(item.price * item.quantity))}</p>
                    </div>
                    <button 
                      onClick={() => removeFromCart(item.id)} 
                      className="text-red-500 hover:text-red-700 transition-colors"
                    >
                      {translations[language].remove}
                    </button>
                  </motion.div>
                ))}
                <div className="mt-4 font-bold text-xl">{translations[language].total}: {formatCurrency(total)}</div>
                <Link 
                  to="/checkout" 
                  className="block w-full bg-green-600 text-white text-center py-2 mt-4 rounded hover:bg-green-700 transition-colors"
                  onClick={() => setIsCartOpen(false)}
                >
                  {translations[language].checkout}
                </Link>
              </>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CartModal;