import React from 'react';
import { motion } from 'framer-motion';
import StarRating from './StarRating';
import { useCart } from '../contexts/CartContext';
import { useCurrency } from '../contexts/CurrencyContext';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../data/translations';

const ProductCard = ({ product, onProductClick }) => {
  const { addToCart } = useCart();
  const { convertPrice, formatCurrency } = useCurrency();
  const { language } = useLanguage();

  return (
    <motion.div
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => onProductClick(product)}
    >
      <img src={product.image} alt={product.name} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="font-semibold">{product.name}</h3>
        <StarRating rating={product.rating} />
        <p className="font-bold mt-2">{formatCurrency(convertPrice(product.price))}</p>
        <button 
          className="w-full bg-green-600 text-white py-2 mt-2 rounded hover:bg-green-700 transition-colors"
          onClick={(e) => {
            e.stopPropagation();
            addToCart(product);
          }}
        >
          {translations[language].addToCart}
        </button>
      </div>
    </motion.div>
  );
};

export default ProductCard;