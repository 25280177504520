import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useCurrency } from '../contexts/CurrencyContext';
import { translations } from '../data/translations';
import { User, Mail, Edit2, Calendar, DollarSign, Package } from 'lucide-react';

const UserPage = () => {
  const { language } = useLanguage();
  const { convertPrice, formatCurrency } = useCurrency();
  const [isEditing, setIsEditing] = useState(false);

  // Здесь можно добавить логику для получения данных пользователя
  const [user, setUser] = useState({
    name: 'Иван Иванов',
    email: 'ivan@example.com',
    orders: [
      { id: 1, date: '2024-03-01', total: 3600, items: 3 },
      { id: 2, date: '2024-02-15', total: 1800, items: 1 },
    ]
  });

  const totalSpent = user.orders.reduce((sum, order) => sum + order.total, 0);
  const totalOrders = user.orders.length;

  const handleEdit = () => {
    setIsEditing(!isEditing);
    // Здесь можно добавить логику сохранения изменений
  };

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-2xl font-bold mb-6">{translations[language].profile}</h1>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex items-center mb-6">
          <div className="w-20 h-20 bg-green-500 rounded-full flex items-center justify-center text-white text-3xl font-bold mr-4">
            {user.name.charAt(0)}
          </div>
          <div>
            <h2 className="text-xl font-semibold">{user.name}</h2>
            <p className="text-gray-600">{user.email}</p>
          </div>
          <button 
            onClick={handleEdit} 
            className="ml-auto bg-green-500 text-white p-2 rounded-full hover:bg-green-600 transition-colors"
          >
            <Edit2 size={20} />
          </button>
        </div>

        {isEditing ? (
          <div className="mb-6">
            <input 
              type="text" 
              value={user.name} 
              onChange={(e) => setUser({...user, name: e.target.value})}
              className="w-full p-2 mb-2 border rounded"
            />
            <input 
              type="email" 
              value={user.email} 
              onChange={(e) => setUser({...user, email: e.target.value})}
              className="w-full p-2 mb-2 border rounded"
            />
            <button 
              onClick={handleEdit}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
            >
              {translations[language].save}
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="flex items-center">
              <User className="text-green-500 mr-2" />
              <span>{translations[language].name}: {user.name}</span>
            </div>
            <div className="flex items-center">
              <Mail className="text-green-500 mr-2" />
              <span>{translations[language].email}: {user.email}</span>
            </div>
            <div className="flex items-center">
              <DollarSign className="text-green-500 mr-2" />
              <span>{translations[language].totalSpent}: {formatCurrency(convertPrice(totalSpent))}</span>
            </div>
            <div className="flex items-center">
              <Package className="text-green-500 mr-2" />
              <span>{translations[language].totalOrders}: {totalOrders}</span>
            </div>
          </div>
        )}

        <h3 className="text-xl font-semibold mb-4">{translations[language].orderHistory}</h3>
        <div className="space-y-4">
          {user.orders.map(order => (
            <div key={order.id} className="border p-4 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <span className="font-semibold">{translations[language].order} №{order.id}</span>
                <span className="text-gray-600">{formatCurrency(convertPrice(order.total))}</span>
              </div>
              <div className="flex items-center text-sm text-gray-600">
                <Calendar size={16} className="mr-2" />
                <span>{order.date}</span>
              </div>
              <div className="text-sm text-gray-600 mt-2">
                {translations[language].items}: {order.items}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserPage;