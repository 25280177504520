import React, { createContext, useState, useContext } from 'react';

const CurrencyContext = createContext();

export const useCurrency = () => useContext(CurrencyContext);

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState('RUB');

  const exchangeRates = {
    RUB: 1,
    USD: 0.011,
    EUR: 0.0093
  };

  const convertPrice = (priceInRUB) => {
    return +(priceInRUB * exchangeRates[currency]).toFixed(2);
  };

  const formatCurrency = (price) => {
    const symbols = { RUB: '₽', USD: '$', EUR: '€' };
    return `${symbols[currency]}${price.toFixed(2)}`;
  };

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency, convertPrice, formatCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};